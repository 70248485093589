import posthog from 'posthog-js'
import Vue from 'vue'

export default function({ app: { router }, store }, inject) {
  const posthogInitAndInject = (promoterOid, userProperties) => {
    // Init PostHog
    posthog.init(process.env.arPosthogApiKey, {
      api_host: process.env.arPosthogUrl,
      capture_pageview: false,
        loaded: () => posthog.identify(promoterOid, userProperties) // If you can already identify your user (Promoter in this case)
    })

    // Inject PostHog into the application and make it available via this.$posthog (or app.$posthog)
    inject('posthog', posthog)
  }

  // Determine if user is logged in
  const isLoggedIn = (auth) => {
    if (!auth || !auth.account || !auth.account.promoterOid) {
      return false
    }

    return true
  }

  // Check if Promoter is part of the allowed list for session recordings
  // List is set as an ENV VAR in Netlify
  const isAllowedPromoter = (promoterOid) => {
    if (!promoterOid)
      return false

    if (process.env.arPosthogAllowedPromoters === '')
      return false

    // include is type strict
    const arPosthogAllowedPromotersArr = process.env.arPosthogAllowedPromoters.split(',');
    if (!arPosthogAllowedPromotersArr.includes(promoterOid.toString())) {
      return false
    }

    return true
  }

  // Get and set properties of logged in user (i.e company name, email address etc)
  // It allows us to add as user properties to PostHog for filter ability
  // Posthog identify() method accepts object in 2nd parameter for properties
  const setUserProperties = (auth) => {
    let userProperties = {}
    if (auth && auth.account && auth.account.companyName)
      userProperties.companyName = auth.account.companyName
    if (auth && auth.account && auth.account.emailAddress)
      userProperties.emailAddress = auth.account.emailAddress
    if (auth && auth.account && auth.account.firstName)
      userProperties.firstName = auth.account.firstName
    if (auth && auth.account && auth.account.lastName)
      userProperties.lastName = auth.account.lastName

      return userProperties
  }

  // Init Posthog
  const initializePromoter = (promoterOid, userProperties) => {
    posthogInitAndInject(promoterOid, userProperties)
  }

  // Make sure that pageviews are captured with each route change
  router.afterEach(to => {
    const {
      auth
    } = store._modules.root.state

    // Ensure user is logged in
    if (!isLoggedIn(auth))
      return

    // Only for specific Promoters
    if (!isAllowedPromoter(auth.account.promoterOid)) {
      return
    }

    let userProperties = setUserProperties(auth);

    // Extract promoter to attach to PostHog
    initializePromoter(auth.account.promoterOid, userProperties)

    Vue.nextTick(() => {
      /* Note: this might also be a good place to call posthog.register(...) in order to update your properties
      on each page view
      */
      posthog.capture('$pageview', {
        $current_url: to.fullPath
      })
    })
  })
}
